<template>
	<v-dialog 
	v-model="dialog"
	max-width="600"
	persistent
	>
		<!-- <template v-slot:activator="{ on, attrs }">
			<v-btn
			color="primary"
			fab
			fixed
			bottom
			right
			v-bind="attrs"
			v-on="on"
			>
				<v-icon class="">mdi-plus</v-icon>
			</v-btn>
		</template> -->
		<v-card>
			<v-card-title>
				<span>Add worker</span>
				<v-spacer></v-spacer>
				<v-btn
					icon
					@click="closeDialog"
				>
					<v-icon> mdi-close </v-icon>
				</v-btn>
			</v-card-title>
			<v-divider></v-divider>

			<v-stepper 
			v-model="currentStep">
				<v-stepper-header style="box-shadow: none;">
					<v-stepper-step
						:complete="currentStep > 1"
						step="1"
					>
						Personal data
					</v-stepper-step>

					<v-divider></v-divider>

					<v-stepper-step
						:complete="currentStep > 2"
						step="2"
					>
						Projects
					</v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="1" class="pt-0">
						
						<div style="width: 200px;">
							<v-select
								v-model="newWorker.status"
								:items="dropdownStatus"
								item-text="name"
								item-value="value"
								label="Status"
							></v-select>
						</div>

						<v-text-field
							v-model="newWorker.personalData.lastName"
							:error="errors.lastName.isError"
							:error-messages="errors.lastName.errorMessage"
							:rules="validationRules.lastNameRules"
							required
							label="Lastname*"
							hint="*required"
							persistent-hint
							@input="() => { errors.lastName.valueEntered = true; setFieldError('lastName', false, '');}"
						></v-text-field>

						<v-text-field
							v-model="newWorker.personalData.firstName"
							:error="errors.firstName.isError"
							:error-messages="errors.firstName.errorMessage"
							:rules="validationRules.firstNameRules"
							required
							label="Firstname*"
							hint="*required"
							persistent-hint
							@input="() => { errors.firstName.valueEntered = true; setFieldError('firstName', false, '');}"
						></v-text-field>

						<v-row class="px-3 py-3">
							<v-dialog
								v-model="birthDateOpened"
								width="290px"
								persistent
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="newWorker.personalData.birthDate"
										prepend-icon="mdi-calendar"
										readonly
										label="Birth date"
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>

								<v-card>
									<v-date-picker
										:min="minBirthDate"
										:max="maxBirthDate"
										v-model="newWorker.personalData.birthDate"									
									></v-date-picker>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											text
											@click="() => {birthDateOpened = false; newWorker.personalData.birthDate=null;}"
										>
											Cancel
										</v-btn>
										<v-btn
											color="primary"
											text
											@click="() => { (newWorker.personalData.birthDate != '') ? birthDateOpened = false : null;}"
										>
											Save
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>	

							<v-text-field
								type="tel"
								v-model="newWorker.personalData.personalId"
								label="External ID"
								counter
								@keypress="isDigit($event)"
								maxlength="50"
								class="ml-10"
							></v-text-field>
						</v-row>

						<v-text-field
							v-model="newWorker.personalData.email"
							label="Email"
							:error="errors.email.isError"
							:error-messages="errors.email.errorMessage"
							:rules="validationRules.emailRules"
							@input="() => { errors.email.valueEntered = true; setFieldError('email', false, ''); }"
						></v-text-field>	

						<v-text-field
							v-model="newWorker.personalData.phoneNumber"
							label="Phone number"
							:error="errors.phoneNumber.isError"
							:error-messages="errors.phoneNumber.errorMessage"
							:rules="validationRules.phoneNumberRules"
							@input="() => { errors.phoneNumber.valueEntered = true; setFieldError('phoneNumber', false, ''); }"
						></v-text-field>	

						<v-textarea
							v-model="newWorker.personalData.comment"
							solo
							label="Comment..."
							outlined
							rows="3"
							maxlength="500"
							counter
							class="mt-3"
						>
						</v-textarea>
						
						<div class="actionButtonsWrapper">
							<v-btn text @click="closeDialog" width="150" class="mr-2">
							Cancel
							</v-btn>
							<v-btn class="actionButton"
							color="primary"
							width="150"
							:disabled="continueDisabled.personalData"
							@click="currentStep=2"
							>
							Continue
							</v-btn>
						</div>
					</v-stepper-content>

					<v-stepper-content step="2">

						<div style='display:flex; align-items: center; background: #eeeeee; height:50px; margin: auto; margin-bottom:20px;'>
							<div color="black" style = 'font-weight:bold; height: fit-content; margin-left: 5px;'>ADD PROJECT</div>
						</div>

						<v-select
							v-model="newWorker.projects"
							:items="projects"
							multiple
							chips
							item-text="name"
							item-value="id"
							label="Project"
						></v-select>	

						<p class="error--text">{{errorMessage}}</p>					

						<div class="actionButtonsWrapper">
							<v-btn text @click="closeDialog" width="150" class="mr-2">
							Cancel
							</v-btn>
							<v-btn
							color="primary"
							width="150"
							@click="addWorker()"
							:disabled="continueDisabled.projects"
							>
							Save
							</v-btn>
						</div>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-card>
		
	</v-dialog>
</template>

<script>
// import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'
// Takes too long! Moved to beforeCreate
//import PhoneNumber from 'awesome-phonenumber';
//const PhoneNumber = () => import('awesome-phonenumber');
var PhoneNumber = null;

export default {
	async beforeCreate() {
		PhoneNumber = await import('awesome-phonenumber');
	},

	name: 'AddRecord',

	data: function(){
		return {
			dialog: false,
			currentStep: 1,
			projects: [],
			dropdownStatus: [
				{
					name: "Active",
					value: 0
				},
				{
					name: "Inactive",
					value: 1
				},
			],
			newWorker: {
				id: null,
				personalData: {
					firstName: null,
					lastName: null,
					email: null,
					phoneNumber: null,
					birthDate: null,
					comment: null,
					personalId: null,
				},
				status: 0,
				projects: []
			},
			errors: {
				lastName: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				firstName: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				email: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				phoneNumber: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				birthDate: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
			},
			validators: {
				lastName: this.validateLastName,
				firstName: this.validateFirstName,
				email: this.validateEmail,
				phoneNumber: this.validatePhoneNumber,
				birthDate: this.validateBirthDate,
			},
			validationRules: {
				lastNameRules: [ v => this.validateLastName(v) || this.errors.lastName.errorMessage ],
				firstNameRules: [ v => this.validateFirstName(v) || this.errors.firstName.errorMessage ],
				emailRules: [ v => this.validateEmail(v) || this.errors.email.errorMessage ],
				phoneNumberRules: [ v => this.validatePhoneNumber(v) || this.errors.phoneNumber.errorMessage ],
				birthDateRules: [ v => this.validateBirthDate(v) || this.errors.birthDate.errorMessage ],
			},
			birthDateOpened: false,
			minBirthDate: null,
			maxBirthDate: null,
			errorMessage: "",
		}
	},
	props: {

    },
	computed: {
		continueDisabled(){
			let continueDisabled = {
				personalData: false,
				projects: false,
			}
			if(!this.errors.firstName.valueEntered || !this.errors.lastName.valueEntered){
				continueDisabled.personalData=true
			}
			else{
				for(let item in this.errors){
					if(this.errors[item].isError){
						continueDisabled.personalData=true
						break
					}
				}
			}
			if(this.newWorker.projects.length==0){
				continueDisabled.projects=true
			}
			return continueDisabled
		}
	},
    mounted(){
		this.minBirthDate = "1920-01-01";
		this.maxBirthDate =  new Date().toJSON().slice(0, 10).replace("/-/g", "/");
		this.fetchProjects()
    },
	methods: {
		fetchProjects(){
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/projects",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: {
					type: 1,
				}
			}).then((response) => {
				this.adaptApiResponseProjects(response.data)
			})
			.catch((error) => {
				if (typeof(error.response) === 'undefined'){
					this.$emit('action-result', 'error', 'Application failed to connect to the server. Please check your internet connection.');
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.$emit('set-state', 'ERROR', "Server received too many reuqests from your browser. Please wait a minute before trying again.");
						return;
					case 400:
						this.$emit('set-state', 'ERROR', "Server rejected your request. Please make sure your application is up to date (reload the website)");
						return;
					case 500:
						this.$emit('set-state', 'ERROR', "An internal server error has occured. Please report this message to the administrator.");
						return;
					default:
						this.$emit('action-result', 'error', "An unknown error has occured. That's all we know");
						return;
				}
			})
		},
		adaptApiResponseProjects(items){
			let projects =[]
			if(items){
				for(let item of items){
						projects.push({
							id: item.id,
							name: item.name
						})
				}
			}
			this.projects=projects
		},
		addWorker(){
			this.$emit("show-overlayer", "Creating worker...");
			axios({
					method:'post',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: {
						personal_data: {
							first_name: this.newWorker.personalData.firstName,
							last_name: this.newWorker.personalData.lastName,
							email: this.newWorker.personalData.email,
							phone_number: this.newWorker.personalData.phoneNumber,
							birth_date: this.newWorker.personalData.birthDate,
							comment: this.newWorker.personalData.comment,
							unique_personal_id: this.newWorker.personalData.personalId,
						},
						status: this.newWorker.status,
						projects: this.newWorker.projects
					}
				}).then(() => {
					this.$emit("hide-overlayer");
					this.$emit("refresh-data", this.newWorker.personalData.lastName+" "+this.newWorker.personalData.firstName);
					this.closeDialog()
				})
				.catch((error) => {
					this.$emit("hide-overlayer");
					if (typeof(error.response) === 'undefined'){
						this.$emit('action-result', 'error', 'Application failed to connect to the server. Please check your internet connection.');
						return;
					}

					switch(error.response.status){
						case 401:
							// Redirect to login page
							this.$router.push("/");
							return;
						case 403:
							this.$emit('action-result', 'error', 'You do not have priviledges to this action');
							return;
						case 429:
							this.errorMessage="Server received too many reuqests from your browser. Please wait a minute before trying again.";
							return;
						case 400:
							console.log(error.response.data)
							if(error.response.data.personal_data && error.response.data.personal_data.unique_personal_id &&  error.response.data.personal_data.unique_personal_id[0].code=="unique"){
								this.$emit('action-result', 'error', 'Personal data with this external ID already exists');
								this.currentStep=1
								return;
							}
							this.errorMessage="Server rejected your request. Please make sure your application is up to date (reload the website)";
							return;
						case 500:
							this.$emit('action-result', 'error', 'An internal server error has occured. Please report this message to the administrator.');
							return;
						default:
							this.$emit('action-result', 'error', "An unknown error has occured. That's all we know");
							return;
					}
				})
		},
		validateLastName: function(lastNameValue) {
			if (this.errors.lastName.valueEntered) {
				if (lastNameValue == "" || lastNameValue == null || lastNameValue == undefined) {
					this.setFieldError("lastName", true, "Last name required");
					return false;
				}

				this.setFieldError("lastName", false, "");
				return true;
			}
			return true;
		},
		validateFirstName: function(firstNameValue) {
			if (this.errors.firstName.valueEntered) {
				if (firstNameValue == "" || firstNameValue == null || firstNameValue == undefined) {
					this.setFieldError("firstName", true, "First name required");
					return false;
				}				
				
				this.setFieldError("firstName", false, "");
				return true;
			}
			return true;
		},
		validateEmail: function(emailValue) {
			if (this.errors.email.valueEntered) {
				if(emailValue == "" || emailValue == null || emailValue == undefined){
					this.setFieldError("email", false, "");
					return true;
				}
				let result = /.+@.+\..+/.test(emailValue);
				if (!result) {
					this.setFieldError("email", true, "Invalid email structure");
					return false;
				}
				this.setFieldError("email", false, "");
				return true;
			}
			this.setFieldError("email", false, "");
			return true
		},
		validatePhoneNumber: function(phoneNumberValue) {
			if (this.errors.phoneNumber.valueEntered) {
				if (phoneNumberValue != null && phoneNumberValue != "") {
					let validators = [
						new PhoneNumber(phoneNumberValue, "PL"),
						new PhoneNumber(phoneNumberValue, "GB"),
						new PhoneNumber(phoneNumberValue, "DE"),
						new PhoneNumber(phoneNumberValue, "UA"),
						new PhoneNumber(phoneNumberValue, "US")
					];

					let anyValidatorValid = false, value;
					validators.forEach(validator => {
						value = validator.isValid();
						if (value) {
							anyValidatorValid = true;
						}
					});

					if (!anyValidatorValid) {
						this.setFieldError("phoneNumber", true, "Invalid phone number structure");
						return false;
					}

					if (!phoneNumberValue.toString().startsWith('+')) {
						this.setFieldError("phoneNumber", true, "No area code entered");
						return false;
					}

					this.setFieldError("phoneNumber", false, "");
					return true;
				}
				this.setFieldError("phoneNumber", false, "");
				return true;
			}
			return true;
		},
		isDigit(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;

			if (charCode >= 48 && charCode <= 57){
				return true;
			}
			evt.preventDefault();
		},
		setFieldError: function(field, value, message) {
			this.errors[field].isError = value;
			this.errors[field].errorMessage = message;
		},
		closeDialog(){
			this.dialog=false
			this.newWorker= {
				personalData: {
					firstName: null,
					lastName: null,
					email: null,
					phoneNumber: null,
					birthDate: null,
					comment: null,
					personalId: null,
				},
				status: 0,
				projects: []
			}
			for(let item in this.errors){
				this.errors[item].isError=false
				this.errors[item].valueEntered=false
				this.errors[item].errorMessage=""
			}
			this.currentStep=1
			this.errorMessage=""
		}
		
	}
};
</script>

<style>
.actionButtonsWrapper{
	display: flex;
	justify-content: flex-end;
}

.v-btn{
	width: 200px;
}


</style>
